<template>
  <div id="pageEditHistory" class="mainContents">
    <UserNavSp :page="'history'" />

    <UserTitle :user="user" />

    <UserNav :user="user" :page="'history'" />

    <HistoryForm ref="historyForm" :prop-history="history" />
  </div>
</template>

<script>
import HistoryForm from '@/components/HistoryForm.vue'
import UserTitle from '@/components/UserTitle.vue'
import UserNav from '@/components/UserNav.vue'
import UserNavSp from '@/components/UserNavSp.vue'

export default {
  components: {
    HistoryForm,
    UserTitle,
    UserNav,
    UserNavSp
  },
  props: {
    history: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      user: {}
    }
  },
  beforeDestroy () {},
  created: function () {
    this.user = this.$store.getters.getLoggedInUser
  },
  mounted () {}
}
</script>

<style scoped></style>
